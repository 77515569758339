import Vue from 'vue';
/** Start Custom imports */
import BootstrapVue from 'bootstrap-vue';
import './custom.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretUp, faCaretDown, faEquals } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueAnalytics from 'vue-analytics';
/** Stop Custom imports */

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';

/** Start custom config */
Vue.use(BootstrapVue);
library.add(faCaretUp, faCaretDown, faEquals);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueAnalytics, {
  id: 'UA-103671601-3',
  router,
});
/** Stop custom config */
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
