import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import('../views/Leaderboard.vue'),
  },
  {
    path: '/success/:type',
    name: 'Success',
    component: () => import('../views/Success.vue'),
  },
  {
    path: '/new-password/:uid/:token',
    name: 'NewPassword',
    component: () => import('../views/NewPassword.vue'),
  },
  {
    path: '/its-me/:id',
    name: 'ItsMe',
    component: () => import('../views/ItsMe.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
